export function removeTraitPoints(str: string) {
  if (str === null || str === undefined) return;

  return str.replace(/[^0-9]/g, '');
}

export function formatPhone(phone: string, isCellPhone?: boolean) {
  if (phone === null || phone === undefined) return;

  if (isCellPhone) {
    return phone
      .replace(/[^0-9]+/g, '')
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{4})/, '($1) $2')
      .replace(/(\d{5})(\d{4})/, '$1-$2');
  } else {
    return phone
      .replace(/[^0-9]+/g, '')
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{4})/, '($1) $2')
      .replace(/(\d{4})(\d{4})/, '$1-$2');
  }
}

export function formatCep(cep: string) {
  if (cep === null || cep === undefined) return;

  return cep
    .replace(/[^0-9]+/g, '')
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{3})/, '$1.$2')
    .replace(/(\d{3})(\d{3})/, '$1-$2');
}

export function formatCpf(cpf: string | null | undefined) {
  if (cpf === null || cpf === undefined) return;

  return cpf
    .replace(/[^0-9]+/g, '')
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

export function formatCnpj(cnpj: string | null | undefined) {
  if (cnpj === null || cnpj === undefined || cnpj === '') return;

  return cnpj
    .replace(/[^0-9]+/g, '')
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1/$2')
    .replace(/(\d{4})(\d{2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}
